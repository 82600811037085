<template>
    <div>
        <div class="l_modal_pop_container" v-if="this.whichPopup != ''">
            <!-- 계약서 날인 방법 -->
            <div class="modal_pop" v-if="this.whichPopup == 'way'" >
                <div class="top">
                    <h3>계약서 날인 방법</h3>
                    <button @click="closePopup" class="c_btn_common btn_close"></button>
                </div>
                <div class="content">
                    <img src="~@/assets/image/service/img_contract.png" alt="계약서 날인 방법" />
                </div>
            </div>

            <!-- 등록 심사 업종 기준 -->
            <div class="modal_pop" v-if="this.whichPopup == 'standard'" >
                <div class="top">
                    <h3>등록 심사 업종 기준</h3>
                    <button @click="closePopup" class="c_btn_common btn_close"></button>
                </div>
                <div class="content">
                    <dl>
                        <dt>불가업종</dt>
                            <dd>경마, 경륜, 대출, 도박/성인사이트(음란물), 기타 미풍양속을 저해하는 업종, 선불카드, 순금/순은, 환금성, 의약품, 저가경매, 휴대폰가입을포함한판매</dd>
                    </dl>
                    <dl>
                        <dt>리스크업종</dt>
                            <dd>구매대행, 귀금속(30만원이상), 금연보조제, 전자담배, 다단계, 담배(전자담배, 해외고객대상 판매 가능), 로또번호 생성 서비 스, 명품, 모바일쿠폰/기프티콘, 미곡상(20만원 이상), 부적, 상품권/복권, 성인사이트 컨텐츠, 성인용품 사이트, 소셜커머스, 아이템, 웨하드, 채팅, 총포/도검, 호스팅/광고, 콘도회원권, 인터넷쇼핑몰분양, 공동구매, 건강/다이어트식품, 광고대행</dd>
                    </dl>
                    <dl>
                        <dt>유의업종</dt>
                            <dd>게임, 경매, 군복 및 군장도구, 도수 있는 안경/썬글라스/콘택트 렌즈, 동창회비, 사이버머니(10만원 초과), 소프트웨어, 숙박, 숙박여행, 식물, 신용카드단말기, 애완동물, 온라인강의, 유흥 구인구직, 전자여권수속(ESTA), 전통주, 정기주차권, 정보 서비스, 주류, 중고차, 증명서 발급, 참가비/회비, 티켓발급 (이미묭, 교통, 항공), 후원금</dd>
                    </dl>
                </div>
            </div>
        </div>

        <!-- 레이어 팝업 배경 -->
        <div class="l_modal_bg" v-if="this.whichPopup != ''"></div>
    </div>
</template>

<script>
export default {
    props:{
        whichPopup : String,
    },
    methods:{
        closePopup(){
            this.$emit('closePopup')
        }
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>