<template>
    <div class="content">
        <!-- 개요 -->
        <div class="overview">
            <p class="l_exclamation">오른편 가입 신청하기에서 가입신청을 해주시면 티온 상담원이 유선으로 상담을 진행할 예정입니다.</p>
            <p class="l_exclamation">서류 제출 완료 후 간단한 심사 절차를 통해 빠른 서비스 적용을 도와드리겠습니다.</p>
        </div>

        <!-- 이용절차 -->
        <div class="process">
            <h2>이용절차</h2>
            <!--p>
                페이앱 신청 구비서류가 모두 제출되고, 간단한 업종심사를 거친 가맹점에 한해 정산이 진행됩니다.<br />
                심사 과정에서 보증보험이 요청된 경우 보험증권이 발행된 다음 평일에 정산됩니다.
            </p-->
            <ul class="service_process">
                <li>
                    <div class="icon_box">
                        <div class="icon_wrapper">
                            <div class="icon"></div>
                        </div>
                    </div>
                    <div class="dot"></div>
                    <p>1.서비스 가입신청</p>
                </li>
                <li>
                    <div class="icon_box">
                        <div class="icon_wrapper">
                            <div class="icon"></div>
                        </div>
                    </div>
                    <div class="dot"></div>
                    <p>2.티온 유선 상담</p>
                </li>
                <li>
                    <div class="icon_box">
                        <div class="icon_wrapper">
                            <div class="icon"></div>
                        </div>
                    </div>
                    <div class="dot"></div>
                    <p>3.안내 서류 접수</p>
                </li>
                <li>
                    <div class="icon_box">
                        <div class="icon_wrapper">
                            <div class="icon"></div>
                        </div>
                    </div>
                    <div class="dot"></div>
                    <p>4.서비스 조건 안내</p>
                </li>
                <li>
                    <div class="icon_box">
                        <div class="icon_wrapper">
                            <div class="icon"></div>
                        </div>
                    </div>
                    <div class="dot"></div>
                    <p>5.서비스 오픈</p>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_service.css"></style>