<template>
    <div class="service_register">

        <ServiceSubTab :navList="navList" />

        <router-view></router-view>

    </div>
</template>

<script>
import ServiceSubTab from '@/components/service/ServiceSubTab'

export default {
    name : 'Register',
    components: {
        ServiceSubTab,
    },
    data(){
        return{
            navList : [
                { to : '/service/register/business', content : '사업자(법인/개인)' },
                { to : '/service/register/personal', content : '개인판매자' },
            ],
        }
    },
}
</script>