<template>
    <div class="l_form">
        <p class="guide_text">은 필수값입니다.</p>
        <div class="required">
            <label>{{this.division == 'business' ? '업체명(법인명)' : '이름'}}</label>
            <div class="input_wrapper">
                <input v-model="name" type="text" class="c_input_basic" placeholder="업체명을 입력하세요." />
            </div>
        </div>
        <div v-if="this.division == 'business'">
            <label>사업자등록번호</label>
            <div class="input_wrapper">
                <input v-model="buisnessman_num" type="text" maxlength="12" class="c_input_basic" placeholder="사업자등록번호를 -없이 입력하세요." />
            </div>
        </div>
        <div class="required">
            <label>휴대폰 번호</label>
            <div class="input_wrapper">
                <input v-model="contact" maxlength="13" type="text" class="c_input_basic" placeholder="휴대폰 번호를 -없이 입력하세요." />
            </div>
        </div>
        <div v-if="this.division == 'business'" class="required">
            <label>사업자 구분</label>
            <div class="input_wrapper">
                <label class="l_check_radio_wrapper">
                    <input type="radio" name="businessType" value="법인" @click="this.buisnessman_division = '법인'" checked />
                    <span>법인</span>
                </label>
                <label class="l_check_radio_wrapper">
                    <input type="radio" value="개인" @click="this.buisnessman_division = '개인'" name="businessType" />
                    <span>개인</span>
                </label>
            </div>
        </div>
        <div v-if="this.division == 'business'" class="required">
            <label>대표자 성함</label>
            <div class="input_wrapper">
                <input v-model="rep" type="text" class="c_input_basic" placeholder="대표자 성함" />
            </div>
        </div>
        <div>
            <label>요청 서비스 구분</label>
            <div class="input_wrapper">
                <select class="c_input_basic" v-model="service_division">
                    <option value="">선택</option>
                    <option value="쇼핑몰결제">쇼핑몰결제</option>
                    <option value="수기결제">수기결제</option>
                    <option value="SMS/URL결제">SMS/URL결제</option>
                    <option value="가상계좌 결제 서비스">가상계좌 결제 서비스</option>
                    <option value="선정산 서비스">선정산 서비스</option>
                    <option value="오프라인 결제 서비스">오프라인 결제 서비스(유/무선 카드단말기, POS, 키오스크)</option>
                </select>
            </div>
        </div>
        <div class="required address">
            <label>주소</label>
            <div class="input_wrapper">
                <div>
                    <input v-model="zipcode" type="text" class="c_input_basic" placeholder="우편번호" readonly disabled/>
                    <button @click="showAddressAPI" class="c_btn_common c_btn_primary-fill">주소찾기</button>
                </div>
                <div>
                    <input v-model="address" type="text" class="c_input_basic" placeholder="주소" readonly disabled/>
                </div>
                <div>
                    <input v-model="address_detail" type="text" id="detailAddress" class="c_input_basic" placeholder="상세주소 입력" />
                </div>
            </div>
        </div>
        <div class="homepage">
            <label>홈페이지 <span class="sub_text">(선택)</span></label>
            <div class="input_wrapper">
                <input v-model="url" type="text" class="c_input_basic" placeholder="http:// 주소를 입력해 주세요." />
                <p>운영하고 계시는 홈페이지 / 블로그 / 쇼핑몰 주소를 입력해주세요. </p>
            </div>
        </div>
        <div class="required terms">
            <label>이용약관</label>
            <div class="input_wrapper">
                <div>
                    <label>
                        <input @click="checkAllAgree($event)" type="checkbox" class="agreementAll" />
                        <span>티온페이 이용약관에 모두 동의합니다.</span>
                    </label>
                </div>
                <div>
                    <div class="termBox" id="privacyPolicy" v-html="privacyPolicy"></div>
                    <label>
                        <input type="checkbox" class="agreement" />
                        <span>개인정보취급방침에 동의합니다.(필수)</span>
                    </label>
                </div>
                <div>
                    <div class="termBox" id="serviceTerms" v-html="serviceTerms"></div>
                    <label>
                        <input type="checkbox" class="agreement" />
                        <span>티온페이 이용약관(필수)</span>
                    </label>
                </div>
                <div>
                    <textarea class="c_textarea_basic" readonly>
시행 일자 : 2021 년 12 월 28 일
주식회사 페이(이하 ‘회사’)는 다음과 같은 원칙에 의하여 이용자의 개인정보를 수집, 이용 및 관리
하고 있고, 이와 관련하여 정보통신 서비스 제공자가 준수하여야 하는 대한민국의 관계 법령 및 개
인정보보호 규정, 가이드라인을 준수하고 있습니다.
                    </textarea>
                    <label>
                        <input type="checkbox" class="agreement" />
                        <span>전자금융거래 이용약관(필수)</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="l_btn_wrapper_bottom">
        <button @click="register" class="c_btn_common c_btn_primary-fill">가입신청</button>
    </div>
</template>

<script>
import { fetchDataEl } from '@/utils/fetchDataEl'
import { postData } from '@/utils/postData';
import { phoneFormat } from '@/utils/formatting/phoneFormat'
import { businessNumFormat } from '@/utils/formatting/businessNumFormat'

export default {
    name : 'Register',
    mixins : [ fetchDataEl, postData, phoneFormat, businessNumFormat ],
    watch : {
        $route(to){
            if(to.path=='/service/register/business'){
                this.division = 'business'
            } else {
                this.division = 'personal'
            }
        },
        // division 바뀔 때 필요없는 값 초기화
        division(newVal){
            if(newVal == 'personal'){
                this.buisnessman_num = '';
                this.buisnessman_division = '';
                this.rep = '';
                this.zipcode = '';
            } else {
                this.buisnessman_division = '법인';
            }
        },
        // 전화번호 formatting
        contact(newVal){
            this.contact = this.phoneFormat(newVal);
        },
        // 전화번호 formatting
        buisnessman_num(newVal){
            this.buisnessman_num = this.businessNumFormat(newVal);
        },
    },
    data(){
        return{
            division : 'business',

            // 입력값
            name : '',
            buisnessman_num : '',
            contact : '',
            buisnessman_division : '법인',
            rep : '',
            service_division : '',
            zipcode : '',
            address : '',
            address_detail : '',
            url : '',

            // 약관
            privacyPolicy : '',
            serviceTerms : '',
        }
    },
    created() {
        if(this.$route.path=='/service/register/business'){
            this.division = 'business'
        } else {
            this.division = 'personal'
        }
    },
    mounted(){
        this.getTerms();
    },
    methods : {
        // 약관 전체동의 체크
        checkAllAgree(event){
            const agreements = document.querySelectorAll('.agreement');
            if(event.currentTarget.checked == false){
                for(let i=0;i<agreements.length;i++){
                    agreements[i].checked = false;
                }
            } else {
                for(let i=0;i<agreements.length;i++){
                    agreements[i].checked = true;
                }
            }
        },
        // 가입신청
        register(){
            
            // 이용약관 모두 동의했는지
            let agree_yn = true;
            const agreements = document.querySelectorAll('.agreement');
            for(let i =0; i<agreements.length; i++){
                if(agreements[i].checked == false){
                    agree_yn = false;
                }
            }
            
            // 사업자일때 필수 확인
            if(this.division == 'business' && (!this.name || this.contact.length < 13 || !this.buisnessman_division || !this.rep || !this.zipcode || !this.address || !this.address_detail)){
                this.$store.commit('error', '필수 입력값을 모두 입력해주세요');
            }
            // 개인일때 필수 확인
            else if(this.division == 'personal' && (!this.name || this.contact.length < 13 || !this.zipcode || !this.address || !this.address_detail)){
                this.$store.commit('error', '필수 입력값을 모두 입력해주세요');
            }
            // 이용약관 동의 확인
            else if(!agree_yn){
                this.$store.commit('error', '필수 이용약관에 모두 동의해주세요');
            }
            // 보내기
            else {
                this.postData('/saveService', {
                    division : this.division=='business' ? '사업자' : '개인판매자',
                    name : this.name,
                    buisnessman_num : this.buisnessman_num,
                    contact : this.contact,
                    buisnessman_division : this.buisnessman_division,
                    rep : this.rep,
                    service_division : this.service_division,
                    zipcode : this.zipcode,
                    address : this.address,
                    address_detail : this.address_detail,
                    url : this.url,
                    agree_yn : agree_yn,
                }, ()=>{
                    this.$store.commit('alert', '서비스 가입이 완료되었습니다.');
                    this.name = '';
                    this.buisnessman_num = '';
                    this.contact = '';
                    this.buisnessman_division = this.division=='business' ? '법인' : '';
                    this.rep = '';
                    this.service_division = '';
                    this.zipcode = '';
                    this.address = '';
                    this.address_detail = '';
                    this.url = '';
                    const agreements = document.querySelectorAll('.agreement');
                    for(let i=0;i<agreements.length;i++){
                        agreements[i].checked = false;
                    }
                    const agreementAll = document.querySelector('.agreementAll');
                    agreementAll.checked = false;
                })
            }
        },
        // 카카오 주소 api
        showAddressAPI() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                    // 각 주소의 노출 규칙에 따라 주소를 조합한다.
                    // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                    let addr = ''; // 주소 변수
                    let extraAddr = ''; // 참고항목 변수

                    //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                    if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                        addr = data.roadAddress;
                    } else { // 사용자가 지번 주소를 선택했을 경우(J)
                        addr = data.jibunAddress;
                    }

                    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                    if(data.userSelectedType === 'R'){
                        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                        if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                            extraAddr += data.bname;
                        }
                        // 건물명이 있고, 공동주택일 경우 추가한다.
                        if(data.buildingName !== '' && data.apartment === 'Y'){
                            extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                        }
                        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                        if(extraAddr !== ''){
                            extraAddr = ' (' + extraAddr + ')';
                        }
                    }

                    // 우편번호와 주소 정보를 해당 필드에 넣는다.
                    this.zipcode = data.zonecode;
                    this.address = addr;
                    // 커서를 상세주소 필드로 이동한다.
                    document.getElementById("detailAddress").focus();
                }
            }).open();
        },
        // 약관 fetch
        getTerms(){
            const privacyPolicyEl = document.getElementById('privacyPolicy');
            const serviceTermsEl = document.getElementById('serviceTerms');
            this.fetchDataEl('/getPrivacypolicy', (data)=>{
                this.privacyPolicy = data.content;
            }, privacyPolicyEl);
            this.fetchDataEl('/getTerm', (data)=>{
                this.serviceTerms = data.content;
            }, serviceTermsEl);
        },
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_service.css"></style>