<template>
    <nav class="sub_tab">
        <router-link
            v-for="(item, i) in this.navList"
            :key="i"
            :to="item.to"
            v-html="item.content"
        ></router-link>
    </nav>
</template>

<script>
export default {
    name : 'ServiceSubTab',
    props:{
        navList : Array,
    }
}
</script>

<style scoped>
/* 서브 탭 START */
.sub_tab {
    margin: 7rem 0;
    padding: var(--box-shadow-size);
    display: flex;
    justify-content: center;
}
.sub_tab a {
    display: block;
    width: 18rem;
    font-size: 1.8rem;
    font-weight: var(--font-w-bold);
    text-align: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-full);
    line-height: 2.8;
    cursor: pointer;
}
.sub_tab a + a {
    margin-left: 3rem;
}
.sub_tab .router-link-active {
    box-shadow: var(--box-shadow-press);
}
@media screen and (max-width: 768px) {
    .sub_tab {
        margin: 3rem 0;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .sub_tab a {
        width: 100%;
        flex-basis: 100%;
    }
    .sub_tab a + a {
        margin-left: 2rem;
    }
}
/* 서브 탭 END */
</style>