<template>
    <div class="c_content_container">
        <section class="c_section c_inner">
            <h1 class="l_page_title">서비스 신청하기</h1>
            
            <LayoutTabTop :navList="navList" />

            <router-view></router-view>
            
        </section>
    </div>
</template>

<script>
import LayoutTabTop from '@/components/LayoutTabTop.vue'

export default {
    components: { 
        LayoutTabTop,
    },
    data(){
        return {
            navList : [
                { to : '/service/guide', content : '이용안내' },
                { to : '/service/register', content : '가입 신청하기' },
            ]
        }
    },
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_service.css"></style>