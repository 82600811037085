import store from '../store'

export const postData = {
    methods: {
        // 해당 url로 데이터 보낸 후 응답오면 콜백 실행
        postData(url, payload, callback=()=>{}, header={}){
            store.commit('startLoading');   // 로딩 시작

            this.axios.post(url, payload, { headers : header })
            .then((res)=>{
                // console.log(res.data);
                store.commit('finishLoading');  // 로딩 완료
                if(res.data.success){
                    if(res.data){
                        callback(res.data);
                    } else {
                        callback();
                    }
                } else {
                    store.commit('error', res.data.message);
                }
            })
            .catch(error=>{
                store.commit('error', '데이터를 보내는 중 문제가 생겼습니다.');
                store.commit('finishLoading');  // 로딩 완료
                console.error(error);
            });
        }
    },
}